import Api from './Api'
import Model from './interface/Model'
import ModelWithFiles from './interface/ModelWithFiles'

export default class BillboardPreset extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'billboard_presets' as string | null,
      plural: 'billboard_presets' as string | null,
    },
  }

  public id: string | null = null

  public type: string = ''

  public name: string = ''

  public image: any = null

  public data: any = {
    sub_type: null, // 'image
    text: null,
    image: null,
    region: null,
    publisher: '',
    content: null,
  }

  public get apiData() {
    return {
      name: this.name,
      type: this.type,
      data: this.data,
      image: this.image,
    }
  }

  public static get billboardTypes() {
    return [
      {
        name: 'Welcome',
        value: 'welcome',
      },
      {
        name: 'Scoreboard',
        value: 'scoreboard',
      },
      {
        name: 'Image',
        value: 'image',
      },
      {
        name: 'Custom',
        value: 'custom',
      },
    ]
  }

  public static get billboardSubTypes() {
    return [

      {
        name: 'Image',
        value: 'image',
      },
      {
        name: 'Text',
        value: 'text',
      },
    ]
  }

  public async save(image: any = null) {
    const api = new Api()

    let data = this.apiData

    if (image) {
      data.image = image
    } else {
      delete data.image
    }

    data.data = JSON.stringify(data.data)

    if (this.id) {
      return api.form(`billboard_presets/${this.id}`, data).then(this.onSave).catch(this.onError)
    }
    return api.form('billboard_presets', data).then(this.onSave).catch(this.onError)
  }
}
