import BillboardPreset from '@/models/BillboardPreset'
import WebMessage from '@/models/WebMessage'
import router from '@/Routes'

export default [
  {
    key: 'id',
    label: 'id',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'updated_at',
    label: 'Updated At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'action',
    label: '',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'action_list',
    actions: [
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        action: (preset: BillboardPreset) => {
          router.push(`/app/billboard-preset/${preset.id}`)
        },
      },
      {
        icon: 'trash',
        title: 'Delete',
        event: 'delete',
        action: (preset: BillboardPreset) => {
          preset.delete().then(response => {
            if (response.data.result.code && response.data.result.code === 'in_use') {
              WebMessage.warning(response.data.message)
            } else if ([201, 200].includes(response.data.code)) {
              WebMessage.success('Deleted successfully')
            }
          })
        },
      },
      {
        icon: 'eye',
        title: 'Details',
        event: 'details',
        top_level: true,
        action: (preset: BillboardPreset) => {
          router.push(`/app/billboard-preset/${preset.id}?mode=preview`)
        },
      },
    ],
  },
]
