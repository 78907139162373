
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ViewModel from '@/models/ViewModel'
import SelectOption from '@/models/interface/SelectOption'
import BillboardPreset from '@/models/BillboardPreset'

@Component({
  components: {
    SelectPicker,
  },
})
export default class BillboardPresetPicker extends ViewModel {
  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: 30 })
  public duration!: number

  @Prop({ default: false })
  public create_panel!: boolean

  @Prop({ default: 'Billboard Preset' })
  public label!: string

  @Prop({ default: () => [] })
  public value!: string[]

  public show_sidebar: boolean = false

  public update: boolean = true

  public uuid: string = ''

  public ready: boolean = false

  public options: SelectOption[] = []

  public busy: boolean = false

  public get can_create() {
    return this.create_panel && this.user.can('creative', 'edit')
  }

  public get panel_callback(): any {
    return this.layout.side_panel_callback.creative
  }

  @Watch('panel_callback')
  public onCallback() {
    if (this.uuid == this.layout.side_panel_id && this.panel_callback) {
      const values = this.value.slice(0)
      values.push(this.panel_callback)
      this.update = true
      this.$emit('input', values)

      this.layout.clearSidePanelCallback()
    }
  }

  public get local_value() {
    return this.value
  }

  public set local_value(value) {
    this.$emit('input', value)
  }

  public mounted() {
    this.uuid = this.randomUUID()
  }

  public created() {
    if (this.edit) {
      if (!this.value || (Array.isArray(this.value) && this.value.length == 0)) {
        this.ready = true
      } else {
        this.busy = true
        BillboardPreset.searchOptions({
          value: this.value,
        }).then(response => {
          this.options = response
          this.busy = false
          this.ready = true
        })
      }
    } else {
      this.ready = true
    }
  }

  public open() {
    if (this.options.length == 0) this.searchOptions()
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    let opts: any = {
      search: search.includes('*') ? search : `*${search}*`,
    }

    BillboardPreset.searchOptions(opts).then(response => {
      if (this.options.length === 1) {
        this.options = [...response, ...this.options]
      } else {
        this.options = response
      }

      this.busy = false
      this.ready = true
    })
  }
}
