
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import BillboardPreset from '@/models/BillboardPreset'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import FilePicker from '@/components/FilePicker/FilePicker.vue'
import RegionPicker from '@/components/RegionPicker/RegionPicker.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import Billboard from '@/models/Billboard'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'

// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-vue2'
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

@Component({
  components: {
    FormInput,
    Widget,
    SelectPicker,
    IconAction,
    ModelFileUploader,
    FilePicker,
    RegionPicker,
    CompanyPicker,
    ckeditor: CKEditor.component,
    CheckboxInput,
    FooterNav,
  },
})
export default class BillboardEdit extends ViewModel {
  @Ref() public validator!: any

  public billboard_preset: BillboardPreset = new BillboardPreset()

  public ready: boolean = false

  public importing_file: boolean = false

  public loading: boolean = false

  public image_preview: any = null

  public image: any = null

  public enable_remote_access: boolean = false

  public editor = ClassicEditor

  @Watch('presetType')
  public onTypeChange(type: string) {
    if (type !== 'welcome') {
      this.billboard_preset.data.sub_type = null
    }
  }

  public get presetType() {
    return this.billboard_preset.type
  }

  @Watch('image')
  public onChangeImage() {
    if (this.image) {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.image_preview = reader.result
      }
      reader.readAsDataURL(this.image)
    } else if (this.image_preview) {
      this.image_preview = null
    }
  }

  public get pageTitle() {
    if (this.$route.params.id) return 'Billboard Preset Edit'
    return 'New Billboard Preset'
  }

  public get previewMode() {
    return this.$route.query && this.$route.query.mode && this.$route.query.mode === 'preview'
  }

  public get option_list() {
    return {
      type: BillboardPreset.billboardTypes,
      sub_type: BillboardPreset.billboardSubTypes,
    }
  }

  public mounted() {
    if (this.$route.params.id) {
      this.loadBillboardPresetById()
    } else {
      this.ready = true
      this.loading = false
    }
  }

  public loadBillboardPresetById() {
    this.loading = true
    BillboardPreset.find(this.$route.params.id)
      .then(response => {
        this.billboard_preset = response
        this.ready = true
        this.loading = false
      })
      .catch(error => {
        this.ready = true
        this.loading = false
      })
  }

  public deleteImage() {
    if (this.billboard_preset.data.image) {
      this.billboard_preset.data.image = null
    }
    if (this.image) {
      this.image = null
      this.image_preview = null
    } else {
      this.image = null
    }
  }

  public save() {
    this.loading = true

    this.billboard_preset
      .save(this.image)
      .then((response: any) => {
        this.loading = false

        if (response.status === 200) {
          this.$router.push({ name: 'billboard-presets' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }
}
