
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ViewModel from '@/models/ViewModel'

import Widget from '@/components/Widget/Widget.vue'
import DataTable from '@/components/DataTable/index.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'

import IconAction from '@/components/IconAction/IconAction.vue'
import BillboardPreset from '@/models/BillboardPreset'
import FormInput from '@/components/FormInput/FormInput.vue'
import Billboard from '@/models/Billboard'
import BillboardPresetPicker from '@/components/Billboard/BillboardPresetPicker.vue'
import ScoreBoardFields from './billboard_presets_fields'

@Component({
  components: {
    Widget,
    DataTable,
    SearchInput,
    IconAction,
    FormInput,
    BillboardPresetPicker,
  },
})
export default class BillboardHome extends ViewModel {
  @Ref() readonly dataTable!: any

  @Ref() public validator!: any

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public query: string[] = []

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public fields: any = []

  public temp_billboard: Billboard = new Billboard()

  public show_filter_helper: boolean = false

  public fieldFilters: any = {
    type: '',
    created_at: '',
    updated_at: '',
    name: '',
  }

  public query_settings: any = {}

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public async scoreboard_presets(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    return BillboardPreset.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  mounted() {
    this.loadFilters()
    this.fields = ScoreBoardFields
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'scoreboard_presets',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('scoreboard_presets').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'scoreboard_presets',
      data: null,
    })
  }

  public refresh(tm = 1) {
    setTimeout(() => {
      this.dataTable.refresh()
    }, tm)
  }

  public resetFilters() {
    this.fieldFilters = {
      type: '',
      created_at: '',
      updated_at: '',
      name: '',
    }
    this.query = []
    this.filter_mode = 'exclusive'
    this.clearFilters()
    this.refresh()
  }
}
