import Api from './Api'
import Model from './interface/Model'
import WebMessage from './WebMessage'

export default class Billboard extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'billboards' as string | null,
      plural: 'billboards' as string | null,
    },
  }

  public id: string | null = null

  public name: string = ''

  public code: any = null

  public pin: any = null

  public billboard_preset_id: any = null

  public show_pin: boolean = false

  public get secure_pin() {
    return this.show_pin ? this.pin : '******'
  }

  public get apiData() {
    return {
      name: this.name,
      code: this.code,
      billboard_preset_id: this.billboard_preset_id,
    }
  }

  public async regenPin() {
    const api = new Api()
    return await api.get(`billboard/regen-pin/${this.id}`).then(response => {
      WebMessage.success(`Updated ${this.name} pin`)
      const data = response.data.result
      this.pin = data.pin
      return data
    })
  }

  public async reload() {
    const api = new Api()
    return await api.get(`billboard/reload/${this.id}`).then(response => {
      WebMessage.success(`Billboard ${this.name} reloaded`)
      const data = response.data.result
      return data
    })
  }

  public showPin() {
    this.show_pin = true
    setTimeout(() => {
      this.show_pin = false
    }, 1000 * 10)
  }
}
